@font-face {
    font-family: 'Golos Text';
    src: url('GolosText-Medium.woff2') format('woff2'),
        url('GolosText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos Text';
    src: url('GolosText-Bold.woff2') format('woff2'),
        url('GolosText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos Text';
    src: url('GolosText-ExtraBold.woff2') format('woff2'),
        url('GolosText-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos Text';
    src: url('GolosText-Regular.woff2') format('woff2'),
        url('GolosText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos Text';
    src: url('GolosText-SemiBold.woff2') format('woff2'),
        url('GolosText-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos Text';
    src: url('GolosText-Black.woff2') format('woff2'),
        url('GolosText-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

