/*
*  html5resetcss
*/
* {
  font-family: 'Golos Text';
  /* font-family:  'Mont'; */
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  scroll-behavior: smooth;
  scroll-padding-top: 120px;

}

body {
  line-height: 1;
  background-color: #F8FBFF
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block
}

nav ul {
  list-style: none
}

blockquote,
q {
  quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: unset;
  font-style: normal;
}

a:focus,
a:active {
  font-style: normal;
  color: unset;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  /* font-weight: bold */
}

del {
  text-decoration: line-through
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0
}

input,
select {
  vertical-align: middle
}

* {
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 8px;
  /* ширина scrollbar */
}

body::-webkit-scrollbar-track {
  background: rgb(177, 177, 177);
  /* цвет дорожки */
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(139, 139, 139);
  /* цвет плашки */
  border-radius: 10px;
  /* закругления плашки */
  /* border: 3px solid rgb(218, 218, 218);  padding вокруг плашки */
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

/*----------------------Переменные-----------------------*/
:root {
  --img-size: 55px;
  --main-color: #1890FF;
  --hover-color: #46a5ff;
  --active-color: #0e71ce;
  --hover-color-alt: rgb(224, 224, 224);
  --active-color-alt: rgb(167, 128, 46);
  --darkBlue: #163C85;
  --lightBlue: #E3EEFF;
  --dullBlue: #F2F8FF;

  --header-height: 70px;

  /* зелёный */
  --base-green-color: #39AA95;

  --default-style-back: #fff;
  /* Фон неоморфизм */
  --default-style-border: 1px solid #e7e7e7;
  /* Обводка неоморфизм */


  /* Тень неоморфизм */
  --default-style-shadow:
    1px 1px 6px #00000030;
  /* -10px -10px 15px #ffffff80,
  10px  10px 15px rgba(70,70,70,0.15); */
  /* none; */

  --default-style-padding: 14px;
  /* Внутренние отступы */
  --default-style-border-rad: 20px;
  /* Закругления */

  /* Неоморфизм внутрь */
  --default-styleIn-shadow:
    inset 5px 5px 10px rgba(70, 70, 70, 0.15),
    inset -5px -5px 15px #ffffff80;

  --default-styleIn-back: linear-gradient(171.1deg, #eef1f5 4.98%, #e6e9ef 94.88%);
  /* Фон неоморфизм */

  /* ------------------------- */

  /*базовые цвета*/
  --base-darkblue-color: #19305D;
  --base-blue-color: #163C85;
  --base-hover-blue-color: #BED8FF;

  --blue-button-color: #2D7FFF;

  --color-purple-default: #8250DF;

  --color-red-default: #FF1B1B;
  --color-red-light-default: #FCD3D3;
  --color-red-click: #FFD0D0;
  --color-red-hover: #FF9D9D;
  
  --color-green-default: #39AA95;
  --color-green-dark-default: #39AA95;
  --color-green-click: #388B7C;
  --color-green-hover: #46D1B7;

  --color-green2-light-default: #daf9e1;
  --color-green2-default: #309D63;

  --color-yellow-default: #FFE5BD;
  --color-yellow-dark-default: #F99500;
  --color-yellow-click: #388B7C;
  --color-yellow-hover: #46D1B7;

  --defaulr-border-color: #d3d3d3;

  /* неактивыне цвета */

  --base-disable-bgc2: #D8D8D8;
  --base-disable-text: #868686;
  --base-disable-bgc: #F3F3F3;


  --anim-time: 0.5s;
  --anim-timing-func: ease;
  --anim-fill-mode: both;

}

/*-------------------------------------------------------*/

/*Виджет с формой Битрикс24*/
.b24-form * {
  flex-direction: column;
}

.default-style {
  background: var(--default-style-back);
  border: var(--default-style-border);
  box-shadow: var(--default-style-shadow);
  padding: var(--default-style-padding);
  border-radius: var(--default-style-border-rad);
  border: 0;
}

.default-styleIn {
  box-shadow: var(--default-styleIn-shadow);
  background: var(--default-styleIn-back);
  border: var(--default-style-border);
  border-radius: var(--default-style-border-rad);
}

#root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,
    'Golos Text',
    'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Mont'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  padding: 0px;
  /*Фон общий*/
  background: #F8FBFF;
  background-size: cover;
  /**/
  font-family: 'Golos Text', sans-serif;
  min-width: 100%;
  width: fit-content;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
p,
a,
span {
  line-height: 100%;
}

h1 {
  font-size: 40px;
  font-weight: 700;
}

h2 {
  font-size: 30px;
  font-weight: 600;
}

h3 {
  font-size: 20px;
  font-weight: 500;
}

h4 {
  font-size: 16;
  font-weight: 600;
}

h5 {
  font-size: 14px;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.round {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.mainwrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
  gap: 35px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 25px;
  width: 100%;
  justify-content: space-between;
}

.leftSectionContent {
  /* width: 5.16%; */
  position: sticky;
  top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 10px;
}

.centerSectionContent {
  width: 69.68%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  gap: 25px;
}

.rightSectionContent {
  width: 21.94%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 25px;
}


.content input {
  padding: 10px 15px;
  border-radius: 10px;
}

input {
  height: 100%;
  border: none;
  background: none;
  width: 100%;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Golos Text', sans-serif;
}

input:focus {
  outline: 0;
}

.error-auth {
  background-color: #464646;
}

.codex-editor {
  width: 80%;
  margin: 0 auto;
}

.codex-editor {
  width: 100%;
}

mark {
  font-style: normal !important;
}

b {
  font-weight: 600 !important;
}